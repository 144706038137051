import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _076cfff8 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _88530e58 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _60d922c8 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _7ab4e7f0 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _5af1a15e = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _1a9798ea = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _b2577dc2 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _e60a9b64 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _cd77933c = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _025ead90 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _520bc083 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _2c594b11 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _42613b07 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _ac18fd56 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _2dc065a3 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _bf97a2a2 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _560eba8c = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _7569ba29 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _a4801daa = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _7447675a = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _4ec43daa = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _076cfff8,
    name: "kits"
  }, {
    path: "/lojas",
    component: _88530e58,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _60d922c8,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _7ab4e7f0,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _5af1a15e,
    name: "motos"
  }, {
    path: "/reset",
    component: _1a9798ea,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _b2577dc2,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _e60a9b64,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _cd77933c,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _025ead90,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _520bc083,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _2c594b11,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _42613b07,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _ac18fd56,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _2dc065a3,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _bf97a2a2,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _560eba8c,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _7569ba29,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _a4801daa,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _7447675a,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _4ec43daa,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
